<template>
    <div class="password-field">
        <label>{{ label }}</label>
        <v-text-field
            autocomplete="off"
            v-bind="$attrs"
            :type="show ? 'text' : 'password'"
            :error-messages="errorMessages"
            v-on="$listeners"
            outlined>
            <template v-slot:append>
                <a class="toggle-visibility" @click="toggleVisibility">
                    {{ show ? 'Hide' : 'Show' }}
                </a>
            </template>
        </v-text-field>
    </div>
</template>

<script>

export default {
    name: 'passwordField',
    data () {
        return {
            show: false
        }
    },
    props: {
        errorMessages: {
            type: Array,
            default: null,
        },
        label: String,
    },
    methods: {
        toggleVisibility () {
            this.show = !this.show;
        }
    }
}
</script>

<style lang="scss">

</style>
